import React, { useState, useEffect } from 'react';

const RouletteSpinner = () => {
  const values = [3000, 2000, 1000, 500, 300]; // Reward amounts in $FIG
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);
  const [reward, setReward] = useState(null);
  const [totalSpins, setTotalSpins] = useState(1); // Initial free spin
  const [lastSpinTime, setLastSpinTime] = useState(localStorage.getItem('lastSpinTime') || null); // Track last spin date

  // Handle the spin effect
  useEffect(() => {
    if (isSpinning) {
      const spinDuration = 3000; // Spin duration in milliseconds
      const intervalDuration = 100; // Interval for spinning effect
      let elapsed = 0;

      const intervalId = setInterval(() => {
        elapsed += intervalDuration;
        setSelectedIndex((prevIndex) => (prevIndex + 1) % values.length);

        if (elapsed >= spinDuration) {
          clearInterval(intervalId);
          setIsSpinning(false);

          // After spinning, pick a random value as the final result
          const finalIndex = Math.floor(Math.random() * values.length);
          setSelectedIndex(finalIndex);
          setReward(values[finalIndex]); // Set the reward to the final value

          // Store the last spin time in localStorage
          const currentDate = new Date().toISOString();
          localStorage.setItem('lastSpinTime', currentDate);
          setLastSpinTime(currentDate);
        }
      }, intervalDuration);

      return () => clearInterval(intervalId); // Cleanup interval on unmount
    }
  }, [isSpinning]);

  // Spin button handler
  const handleSpin = () => {
    if (isSpinning || totalSpins <= 0) return; // Prevent spinning if already spinning or no spins left

    setIsSpinning(true);
    setReward(null); // Reset reward before starting a new spin

    setTotalSpins((prev) => prev - 1); // Decrease available spins after each spin
  };

  // Buy spins handler (using Telegram stars)
  const handleBuySpins = () => {
    // Implement purchasing logic with Telegram stars (dummy for now)
    const newSpins = 5; // Grant 5 spins for purchase
    setTotalSpins((prev) => prev + newSpins);
    alert(`You have successfully purchased ${newSpins} spins using Telegram stars!`);
  };

  // Check if the user can spin today
  const canSpinToday = () => {
    if (!lastSpinTime) return true; // If never spun, they can spin
    const lastSpinDate = new Date(lastSpinTime);
    const today = new Date();

    return lastSpinDate.toDateString() !== today.toDateString(); // Check if last spin was not today
  };

  return (
    <div className="roulette-spinner w-full flex flex-col items-center space-y-3">
      <h1 className="text-[13px] text-secondary">FREE SPINS</h1>

      <div className="roulette-container w-full h-[300px] bg-[#17181A] rounded-[12px] relative flex items-center justify-center">
        {/* Spinner Layout */}
        <div className="overlay absolute top-0 left-0 w-full h-[10px] bg-[#17181A] opacity-[90%] rounded-t-[12px]"></div>
        <div className="overlay absolute top-0 left-0 w-full h-[70px] bg-[#17181A] opacity-[75%] rounded-t-[12px]"></div>
        <div className="overlay absolute bottom-0 left-0 w-full h-[128px] bg-[#17181A] opacity-[75%] rounded-b-[12px]"></div>
        <div className="overlay absolute bottom-0 left-0 w-full h-[100px] bg-[#17181A]"></div>

        {/* Spinner Values */}
        <div className="spinner-content w-full h-[300px] flex flex-col items-center justify-center z-10 space-y-2">
          {values.map((value, index) => (
            <div
              key={index}
              className={`flex items-center space-x-1 font-medium transition-transform duration-300 ease-in-out ${
                index === selectedIndex ? 'scale-110 text-white' : 'text-gray-400'
              }`}
            >
              <img src="/stars2.svg" alt="stars" className="w-[14px]" />
              <span>{value}</span>
            </div>
          ))}
        </div>

        {/* Spin Button */}
        <div className="spin-button-container absolute bottom-5 left-0 right-0 px-6 flex justify-center items-center">
          <button
            className="bg-btn font-semibold flex justify-center rounded-[8px] items-center h-[50px] w-full"
            onClick={handleSpin}
            disabled={isSpinning || !canSpinToday()} // Disable if already spinning or can't spin today
          >
            {canSpinToday() ? 'Spin' : 'You can spin tomorrow!'}
          </button>
        </div>
      </div>

      {/* Reward Info */}
      {reward !== null && (
        <div className="reward-info mt-4 text-center">
          <h2 className="text-xl font-bold text-white">
            Congratulations! You won {reward} $FIG!
          </h2>
        </div>
      )}

      {/* Buy Spins */}
      <div className="buy-spins-container mt-4">
        <button
          className="bg-btn text-white font-semibold p-2 rounded-md"
          onClick={handleBuySpins}
        >
          Buy 5 Spins for Telegram Stars
        </button>
      </div>

      {/* Spin Info */}
      <p className="text-[13px] text-secondary text-center mt-2">
        Spin to win $FIG! You have one free spin every 24 hours. Get 5 additional spins for every purchase using Telegram stars.
      </p>
    </div>
  );
};

export default RouletteSpinner;
