import { useEffect, useRef } from "react";
import "swiper/css";
import "swiper/css/pagination";

export default function SwiperComponent() {
  const swiperRef = useRef(null);
  const totalSlides = 4; // Update based on the number of slides

  useEffect(() => {
    if (typeof window !== "undefined") {
      import("swiper").then(({ default: Swiper }) => {
        swiperRef.current = new Swiper(".sample-slider", {
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
            renderBullet: function (i, classes) {
              const tabs = ["Image 1", "Image 2", "Image 3", "Image 4"];
              return `<span class="${classes}">${tabs[i]}</span>`;
            },
          },
          speed: 500,
          loop: false, // No infinite loop
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          on: {
            slideChange: function () {
              if (swiperRef.current?.activeIndex === totalSlides - 1) {
                setTimeout(() => {
                  window.location.href = "/"; // Redirect to homepage on last slide
                }, 1500);
              }
            },
          },
        });
      });
    }
  }, []);

  // Navigate to the next slide when an image is clicked
  const handleImageClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  return (
    <>
      {/* External Styles */}
      <style jsx global>{`
        .sample-slider {
          width: 100%;
          height: 100vh;
          position: relative;
        }

        .swiper-slide img {
          width: 100%;
          height: auto;
          object-fit: cover;
          cursor: pointer;
        }

        .swiper-pagination {
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          justify-content: center;
          gap: 10px;
        }

        .swiper-pagination-bullet {
          background: lightgrey;
          padding: 10px 20px;
          border-radius: 5px;
          font-size: 14px;
          cursor: pointer;
          transition: background 0.3s, transform 0.3s;
        }

        .swiper-pagination-bullet-active {
          background: #3498db;
          transform: scale(1.1);
        }

        .swiper-pagination-bullet:hover {
          background: #2980b9;
          color: white;
        }
      `}</style>

      {/* Swiper Component */}
      <div className="swiper sample-slider">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <img
              src="/images/image.jpg"
              alt="Sample 1"
              onClick={handleImageClick}
            />
          </div>
          <div className="swiper-slide">
            <img
              src="/images/image2.jpg"
              alt="Sample 2"
              onClick={handleImageClick}
            />
          </div>
          <div className="swiper-slide">
            <img
              src="/images/image3.jpg"
              alt="Sample 3"
              onClick={handleImageClick}
            />
          </div>
          <div className="swiper-slide">
            <img
              src="/images/image4.jpg"
              alt="Sample 4"
              onClick={handleImageClick}
            />
          </div>
        </div>
        <div className="swiper-pagination"></div>
      </div>
    </>
  );
}
